import { CardTitle } from '@careevolution/mydatahelps-ui';
import { faAngleUp, faAngleDown, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { IDateFilter } from '../helpers/studyDurationHelpers';
import "./Accordian.css";

export interface IAccordianProps {
  title: string,
  title2: string,
  content: any,
  duration : IDateFilter,
  moveBackward: any, 
  moveForward: any
};

const Accordion = (props: IAccordianProps) => {
    const [isActive, setIsActive] = useState(true);
  
    return (
      <li className="accordion-item">
        <div className="accordion-toggle accordianTitleBar leftMargins">
            <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
              <div style={{ display: isActive===true? 'inline' : 'none'}}><FontAwesomeIcon icon={faAngleUp}/></div>
              <div style={{ display: isActive===false? 'inline' : 'none'}}><FontAwesomeIcon icon={faAngleDown}/></div>
              <div className='accordianColumn'>
                <div className="goalTitle1">{props.title}</div>
                <div><CardTitle title={props.title2}/></div>
              </div>
            </div>
        </div>
        {isActive &&
          <div>
            <div className="accordion-content">{props.content}</div>
            <div className="pagerRow pagerText">
              <div onClick={() => props.moveBackward()} className="clickableNav"><FontAwesomeIcon className='iconSize pagerBackwardIconPadding' icon={faAngleLeft}/>Previous Week</div>
              <div>{`${props.duration.startDate.toLocaleString({ month: 'short', day: 'numeric' })} - ${props?.duration.endDate.toLocaleString({ month: 'short', day: 'numeric' })}`}</div>
              <div onClick={() => props.moveForward()} className="clickableNav">Next Week<FontAwesomeIcon className='iconSize pagerForwardIconPadding' icon={faAngleRight}/></div>
            </div>
          </div>
        }
      </li>
    );
  };
  
  export default Accordion;