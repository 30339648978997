import React from 'react';
import "./Streak.css";

export default function (props: {streak: number} ) {
    
    function getCurrentReward(){
        var dailyReward : number = 0;
        switch (props.streak) {
            case 1: 
                dailyReward = 1;
                break;
            case 2: 
                dailyReward = 1.5;
                break;
            case 3:
                dailyReward = 2;
                break;
            case 4:
                dailyReward = 2.5;
                break;
            case 5: 
                dailyReward = 3;
                break;
            case 6:
                dailyReward = 3.5;
                break;
            case 7: 
                dailyReward =  4;
                break;
            default: 
                if (props.streak > 7){
                    dailyReward = 4;
                }
         }

         return `$${dailyReward.toFixed(2)}`;
    }

    return (
            <div className='divStreak'>
            <div className='divTrophy'>
                <img src="images/trophy.svg"/>
            </div>
            <div>
                <div className="streakHeader divStreakDetails">CURRENT STREAK</div>
                <div className="streakDetail"><span>{props.streak}</span> {props.streak === 1 ? 'Day' : 'Days'}</div>
                <div className="streakHeader divStreakDetails">TODAY'S REWARD</div>
                <div className="streakDetail">{getCurrentReward()}</div>
            </div>
        </div>
	)
}
