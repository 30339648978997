import React, { useEffect, useState } from 'react';
import { IDataPoint } from './MyData';
import './SingleLineChart.css';
import { debounce, isNumber, maxBy, minBy, omit } from "lodash";
import { getNiceTickValues } from "recharts-scale";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import MyDataHelps from '@careevolution/mydatahelps-js';
import { LoadingIndicator } from '@careevolution/mydatahelps-ui';

  const Trophy = ( props : any) => {
    const { x, y } = props.viewBox;
    
    return (
      <path
        d="M14.375 1.625H11.6667V0.609375C11.6667 0.27168 11.388 0 11.0417 0H3.95833C3.61198 0 3.33333 0.27168 3.33333 0.609375V1.625H0.625C0.278646 1.625 0 1.89668 0 2.23437V3.65625C0 4.5627 0.585938 5.49453 1.61198 6.21309C2.43229 6.78945 3.42969 7.15508 4.47656 7.27187C5.29427 8.59473 6.25 9.14063 6.25 9.14063V10.9688H5C4.08073 10.9688 3.33333 11.4943 3.33333 12.3906V12.6953C3.33333 12.8629 3.47396 13 3.64583 13H11.3542C11.526 13 11.6667 12.8629 11.6667 12.6953V12.3906C11.6667 11.4943 10.9193 10.9688 10 10.9688H8.75V9.14063C8.75 9.14063 9.70573 8.59473 10.5234 7.27187C11.5729 7.15508 12.5703 6.78945 13.388 6.21309C14.4115 5.49453 15 4.5627 15 3.65625V2.23437C15 1.89668 14.7214 1.625 14.375 1.625ZM2.58594 4.89531C1.95052 4.44844 1.66667 3.95078 1.66667 3.65625V3.25H3.33854C3.36458 4.07773 3.48958 4.80391 3.67188 5.43867C3.27865 5.30664 2.91146 5.12383 2.58594 4.89531ZM13.3333 3.65625C13.3333 4.06504 12.8724 4.57285 12.4141 4.89531C12.0885 5.12383 11.7188 5.30664 11.3255 5.43867C11.5078 4.80391 11.6328 4.07773 11.6589 3.25H13.3333V3.65625Z"
        fill="#FFBF3C"
        id="trophy"
        transform={`translate(${x - 50} ${y - 7.5})`}
      />
    );
  };

  const SingleLineChart = (props: {
    data?: IDataPoint[];
    dataGoal: number;
  }) => {

    const [gradientId] = useState(`lineGradient-${10000*Math.random()}`);
    const [max, setMax] = useState(maxBy(props.data, "dpValue")?.dpValue || 100);
    const [min] = useState(minBy(props.data, "dpValue")?.dpValue || 0);
    const [shouldUseGradient] = useState(min <= props.dataGoal && max > props.dataGoal);
    const [solidColor] = useState(min <= props.dataGoal && max <= props.dataGoal ? '#00AE42' : '#C40D3C');
    const [loading, setLoading] = useState(true);
    const ticks = getNiceTickValues([0, max], 5, false);
    const showWeekDay = props.data ? ( props.data.length ===7 ? true : false ) : true;

    const Dot = (dotProps: any) => {
      const circProps = omit(dotProps, "dataKey");
  
      if (!isNumber(circProps.value)) {
        return <circle style={{ display: "none" }} key={dotProps.key} />;
      }
      return (
        <circle
          key={dotProps.key}
          {...circProps}
          stroke={
            circProps.value <= props.dataGoal ? "#00AE42" : "#C40D3C"
          }
          fill={circProps.value <= props.dataGoal ? "#00AE42" : "#C40D3C"}
        />
      );
    };

    const XAxisTick = (value : any) => {
      const date = new Date(value);
      // Check for monthly/weekly chart
      if (!showWeekDay) {
        return date.toLocaleString('en-us', { month: 'short', day: 'numeric' });
      }
      return date.toLocaleDateString('en-us', {weekday: 'short'});
    }

    function initialize(){
      var mx = maxBy(props.data, "dpValue")?.dpValue || 100;
      if (mx < props.dataGoal){
        mx = props.dataGoal;
      }
      setMax(mx);
      setLoading(false);
    }
    
    useEffect(() => {
      let debouncedInitialize = debounce(initialize, 500);
  
      debouncedInitialize();
  
      MyDataHelps.on("applicationDidBecomeVisible", debouncedInitialize);
      
      return () => {
        MyDataHelps.off("applicationDidBecomeVisible", debouncedInitialize);
      } 
    }, []);
  
    if (loading ) {
      return <LoadingIndicator/>;
    }
    
    return (
      <ResponsiveContainer width="95%" height={200}>
          <LineChart
            height={200}
            data={props.data}
            margin={{ top: 25, right: 10, left: 10, bottom: 5 }}
          >
            <defs>
            <linearGradient id="backgroundGrad" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0" stopColor="#FDFDFD" stopOpacity={0} />
                <stop offset="1" stopColor="#EDEDED" stopOpacity={1} />
             </linearGradient>
      
              { shouldUseGradient ? (
                <linearGradient
                  id={gradientId}
                  x1="0%"
                  y1="100%"
                  x2="0%"
                  y2="0%"
                >
                  <stop
                    offset="0%"
                    stopColor={"#00AE42"}
                  />
                  <stop
                    offset={`${((props.dataGoal - min) / (max - min)) * 100}%`}
                    stopColor={"#00AE42"}
                  />
                  <stop
                    offset={`${((props.dataGoal - min) / (max - min)) * 100}%`}
                    stopColor={"#C40D3C"}
                  />
                  <stop
                    offset="100%"
                    stopColor={"#C40D3C"}
                  />
                </linearGradient>
              ) : null}
            </defs>
      
            <CartesianGrid
              vertical={false}
              stroke="#A2A9AD"
              fill="url(#backgroundGrad)"
            />
            {showWeekDay && <XAxis  dataKey="dpDate" tickLine={false} tickFormatter={XAxisTick} interval={1} padding={{ left: 20, right: 20 }}/>}
            {!showWeekDay && <XAxis dataKey="dpDate" tickLine={false} tickFormatter={XAxisTick} interval="preserveStartEnd" padding={{ left: 20, right: 20 }}/>}
            <YAxis 
              ticks={ticks} 
              tickLine={false} 
              tickCount={5} 
              axisLine={false} 
              domain={[0, 'dataMax']} 
              allowDecimals={false} 
            />
            <ReferenceLine
              y={props.dataGoal}
              stroke="#FFBF3C"
              strokeWidth={3}
              label={<Trophy />}
            />
            <Line
              connectNulls
              type="monotone"
              dataKey="dpValue"
              stroke={shouldUseGradient ? `url(#${gradientId})` : solidColor}
              strokeWidth={5}
              dot={Dot}
            />
          </LineChart>
      </ResponsiveContainer>
    );
  }

  SingleLineChart.defaultProps = {
    dataGoal: 0
  }

  export default SingleLineChart