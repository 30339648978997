import MyDataHelps from '@careevolution/mydatahelps-js';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";
import Home from './views/Home';
import MyData from './views/MyData';
import Faq from './views/Faq';

<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'></link>


ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/home/" element={<Home />} />
				<Route path="/mydata/" element={<MyData/>} />
				<Route path="/faq/" element={<Faq />} />
			</Routes>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);