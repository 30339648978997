import React, {useEffect, useState} from 'react';
import './Home.css';
import MyDataHelps, { SurveyAnswersQuery } from '@careevolution/mydatahelps-js';
import * as Model from "@careevolution/mydatahelps-js/types";
import { Layout, Card } from "@careevolution/mydatahelps-ui";
import WeeklyGoals from './WeeklyGoals';
import Accordion from './Accordian';
import Streak from './Streak';
import Rewards from './Rewards';
import * as confetti from "../components/Confetti.js";
import {loadStudyDurationWeeks, sameDay, calcDaysInStudy, IDateFilter, isDayInRange} from "../helpers/studyDurationHelpers";
import { DateTime } from "luxon";

const debounce = require('lodash.debounce');

export interface IGoalWeeklyProgress {
	goalName: string, 
	progress: string[],
};

export interface IWeeklyReport {
	alcoholProgress: IGoalWeeklyProgress,
	tobaccoProgress: IGoalWeeklyProgress,
	substanceProgress: IGoalWeeklyProgress,
	personalProgress: IGoalWeeklyProgress
	duration: IDateFilter
};

interface IStudyData {
	weeklyData : IWeeklyReport[],
	participantActiveWeekIndex: number
}

export interface IRewardsProfile {
	streak : number,
	totalEarned : number,
	daysInStudy : number,
	gcDate1? : Date,
	gcDate2? : Date,
	gcDate3? : Date,
	gcDate4? : Date,
	gcDate5? : Date,
};

export default function Home() {
	const [_weeklyData, updateWeeklyData] = useState<IWeeklyReport>();
	const [_studyData, setStudyData] = useState<IStudyData>({weeklyData: [], participantActiveWeekIndex: 0});
	const [_weekIndexInView, setWeekIndexInView] = useState(0);
	const [totalStudyWeeks, setTotalStudyWeeks] = useState(0);

	const [dailyGoalsAchieved, updateDailyGoalsAchieved] = useState(false);
	const [justCompletedSurvey, setJustCompletedSurvey] = useState(false);
	const [participantCompletedStudy, setParticipantCompletedStudy] = useState(false);
	const [sameDayGiftCard, setSameDayGiftCard] = useState(false);
	const [lastGiftCard, setLastGiftCard] = useState(false);

	const [loadingParticipantInfo, setLoadingParticipantInfo] = useState(true);
	const [loadingWeeklyReport, setLoadingWeeklyReport] = useState(true);
	const [rewardsProfile, setRewardsProfile] = useState<IRewardsProfile>({streak: 0, totalEarned: 0, daysInStudy : 0} );
	const _studyLengthInDays : number = 28;
	const _dailyMeasuresSurveyName : string = "ASPIRE-2 Daily Measures";
	const mdh = MyDataHelps; 

	function initialize() {
		loadParticipant();
	}

	function loadParticipant(){
		//Load Participant Info
		setLoadingParticipantInfo(true);

		MyDataHelps.getParticipantInfo().then( function(participantInfo) {
			var enrollmentDate = participantInfo.enrollmentDate;
			var daysInStudy : number = calcDaysInStudy(DateTime.fromISO(enrollmentDate));

			let customFields = participantInfo.customFields;
			loadRewardsProfile(customFields, daysInStudy);

			if (daysInStudy > _studyLengthInDays){
				setParticipantCompletedStudy(true);
			}
			
			if (daysInStudy > 0 && daysInStudy <= _studyLengthInDays){
				loadDailySurvey(daysInStudy);
			} else {
				loadParticipantStudyData(daysInStudy);
			}

			setLoadingParticipantInfo(false);
		}).catch(function (error) {
			console.log(`Error loading participant info ${error}`);
		});
	}

	function loadRewardsProfile(customFields :{[key : string] : string}, daysInStudy: number){
		var streak = customFields.hasOwnProperty('Streak') && customFields['Streak'].trim().length > 0 ? Number(customFields['Streak']) : 0;
		var totalEarned : number = 0;

		var json = customFields.hasOwnProperty('Rewards') && customFields['Rewards'].trim().length > 0 ? customFields['Rewards'] : "";
		if (json !== ""){
			let savedDetails = JSON.parse(json);
			if (savedDetails.rewardDetails.length > 0){
				let mrReward = savedDetails.rewardDetails[savedDetails.rewardDetails.length -1];
				totalEarned = mrReward.totalRewardsEarned;
			}
		}
		
		var gcDate1 = customFields.hasOwnProperty('GiftCard1Date') && customFields['GiftCard1Date'].trim().length > 0 ? DateTime.fromISO(customFields['GiftCard1Date']) : undefined;
		var gcDate2 = customFields.hasOwnProperty('GiftCard2Date') && customFields['GiftCard2Date'].trim().length > 0 ? DateTime.fromISO(customFields['GiftCard2Date']) : undefined;
		var gcDate3 = customFields.hasOwnProperty('GiftCard3Date') && customFields['GiftCard3Date'].trim().length > 0 ? DateTime.fromISO(customFields['GiftCard3Date']) : undefined;
		var gcDate4 = customFields.hasOwnProperty('GiftCard4Date') && customFields['GiftCard4Date'].trim().length > 0 ? DateTime.fromISO(customFields['GiftCard4Date']) : undefined;
		var gcDate5 = customFields.hasOwnProperty('GiftCard5Date') && customFields['GiftCard5Date'].trim().length > 0 ? DateTime.fromISO(customFields['GiftCard5Date']) : undefined;
		var today = DateTime.now();
		if (sameDay(today, gcDate1) || sameDay(today, gcDate2) || sameDay(today, gcDate3) || sameDay(today, gcDate4)) {
			setSameDayGiftCard(true);
		}
		if (sameDay(today, gcDate5)) {
			setLastGiftCard(true);
		}

		setRewardsProfile({
			streak: streak, totalEarned: totalEarned, daysInStudy: daysInStudy, 
			gcDate1: gcDate1 ? gcDate1.toJSDate() : gcDate1, 
			gcDate2: gcDate2 ? gcDate2.toJSDate() : gcDate2,
			gcDate3 : gcDate3 ? gcDate3.toJSDate() : gcDate3, 
			gcDate4: gcDate4 ? gcDate4.toJSDate() : gcDate4,
			gcDate5: gcDate5 ? gcDate5.toJSDate() : gcDate5
		});
	}

	function loadParticipantStudyData(daysInStudy: number){
		const today : DateTime = DateTime.now().startOf('day');
		const _otherDaysInWeek = 6;
		const durations = loadStudyDurationWeeks(daysInStudy);
		
		var parameters : SurveyAnswersQuery = {
			surveyName : _dailyMeasuresSurveyName, //'ASPIRE-2 Daily Measures',
			after : durations.startDate.toString(),
			before : durations.endDate.toString(),
			stepIdentifier : 'Summary'
		};

		MyDataHelps.querySurveyAnswers(parameters).then( function(result) {
			setLoadingWeeklyReport(true);

			var currentWeekIndex = -1;
			var surveyAnswers = result.surveyAnswers;
			let newStudyData : IStudyData = {weeklyData: [], participantActiveWeekIndex: 0};
			var startDate = durations.startDate;
			var studyWeeks = Math.abs(Math.floor(durations.startDate.diff(durations.endDate, "weeks").weeks));

			for( var xx : number = 0; xx < studyWeeks; xx++){
				let endDate = startDate.plus({'days' : _otherDaysInWeek}).endOf("day");
				let weekData = surveyAnswers.filter(a => DateTime.fromISO(a.date) >= startDate && DateTime.fromISO(a.date) <= endDate);
				let modeledData = loadDataIntoWeeklyModel(startDate, endDate, weekData);
				if (isDayInRange(today, startDate, endDate)){
					currentWeekIndex = xx;
				}
				newStudyData.weeklyData.push(modeledData);
				startDate = endDate.plus({'days' : 1}).startOf("day");
			}

			newStudyData.participantActiveWeekIndex = currentWeekIndex >= 0 ? currentWeekIndex : (studyWeeks - 1);
			
			setStudyData(newStudyData);
			setTotalStudyWeeks(studyWeeks);
			loadWeeklyDataIntoView(newStudyData.participantActiveWeekIndex, newStudyData.weeklyData[newStudyData.participantActiveWeekIndex] );
			setLoadingWeeklyReport(false);
		})
	}

	function loadDataIntoWeeklyModel(startDate : DateTime, endDate : DateTime, weeklyData? : Model.SurveyAnswer[]) : IWeeklyReport{
		const today = DateTime.now().startOf('day');
		var dateInContext = startDate;
		var alcoholReport: IGoalWeeklyProgress = { goalName: "ALCOHOL", progress: [] };
		var tobaccoReport: IGoalWeeklyProgress = { goalName: "TOBACCO", progress: [] };
		var substanceReport: IGoalWeeklyProgress = { goalName: "SUBSTANCE", progress: [] };
		var personalReport: IGoalWeeklyProgress = { goalName: "PERSONAL", progress: [] };
		
		for (let i = 1; i < 8; i++) {
			var dailySummary;
			if (weeklyData) {
				dailySummary = weeklyData.find(a => sameDay(DateTime.fromISO(a.date).startOf('day'), dateInContext));
			}
			if (!dailySummary) {
				alcoholReport.progress.push("none");
				tobaccoReport.progress.push("none");
				substanceReport.progress.push("none");
				personalReport.progress.push("none");
			} else {
				var summary = JSON.parse(dailySummary.answers[0]);
				alcoholReport.progress.push(summary.AlcoholGoalMet ? "true" : "false");
				tobaccoReport.progress.push(summary.TobaccoGoalMet ? "true" : "false");
				substanceReport.progress.push(summary.SubstanceGoalMet ? "true" : "false");
				personalReport.progress.push(summary.DailyGoalMet ? "true" : "false");

				if (sameDay(dateInContext, today)){
					if (summary.AlcoholGoalMet === true
						&& summary.TobaccoGoalMet === true
						&& summary.SubstanceGoalMet === true 
						&& summary.DailyGoalMet === true ){
							updateDailyGoalsAchieved(true);
					}
				}
			}
			dateInContext = dateInContext.plus({'days' : 1});
		}

		var weekDuration : IDateFilter = {startDate: startDate, endDate: endDate};

		return {
			alcoholProgress : alcoholReport, 
			tobaccoProgress : tobaccoReport, 
			substanceProgress : substanceReport, 
			personalProgress : personalReport,
			duration : weekDuration
		}
	}

	function loadDailySurvey(daysInStudy : number){
		var queryParameters : Model.SurveyTaskQueryParameters = {
			status: "incomplete",
			surveyName: _dailyMeasuresSurveyName,
			limit: 1
		};

		MyDataHelps.querySurveyTasks(queryParameters).then( function(result) {
			if (result.surveyTasks.length > 0){
				mdh.startSurvey(_dailyMeasuresSurveyName);
			} else {
				loadParticipantStudyData(daysInStudy);
			}
		}).catch(function (error) {
			console.log(`Error loading ${_dailyMeasuresSurveyName} survey`);
			console.log(error);
		});
	}

	function surveyDidFinish(result : any){
		if (result.reason === 'Completed') {
			setJustCompletedSurvey(true);
		}
	} 
	
	function moveForward (){
		var newIndex = _weekIndexInView + 1;
		if (newIndex < totalStudyWeeks) {
			loadWeeklyDataIntoView(newIndex);
		}
	}

	function moveBackward(){
		var newIndex = _weekIndexInView - 1;
		if (newIndex >= 0) {
			loadWeeklyDataIntoView(newIndex);
		}
	}

	function loadWeeklyDataIntoView(useIndex : number, weeklyData? : IWeeklyReport){
		if (weeklyData){
			updateWeeklyData( { 
				alcoholProgress : weeklyData.alcoholProgress, 
				tobaccoProgress : weeklyData.tobaccoProgress, 
				substanceProgress : weeklyData.substanceProgress, 
				personalProgress : weeklyData.personalProgress,
				duration: weeklyData.duration
			});
		} else {
			
			updateWeeklyData( { 
				alcoholProgress : _studyData.weeklyData[useIndex].alcoholProgress, 
				tobaccoProgress : _studyData.weeklyData[useIndex].tobaccoProgress, 
				substanceProgress : _studyData.weeklyData[useIndex].substanceProgress, 
				personalProgress : _studyData.weeklyData[useIndex].personalProgress,
				duration: _studyData.weeklyData[useIndex].duration
			});
		}
		setWeekIndexInView(useIndex);
	}


	useEffect(() => {
		let debouncedInitialize = debounce(initialize, 1500);

		debouncedInitialize();

		MyDataHelps.on("applicationDidBecomeVisible", debouncedInitialize);
		MyDataHelps.on("surveyDidFinish", surveyDidFinish);
		
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", debouncedInitialize);
		} 
	}, []);

	useEffect(() => {
		const endOfStudyReward = "Congratulations! You have reached the end of the study. We will send the remaining balance to you. Thanks for participating!";
		let message = "";
		
		if (justCompletedSurvey) {
		  if (dailyGoalsAchieved) {
			if (sameDayGiftCard) {
			  message = "Congratulations! You achieved ALL of your goals yesterday! You've earned $20! Study staff will be sending it to you.";
			} else if (lastGiftCard) {
			  message = endOfStudyReward;
			} else {
			  message = "Congratulations! You achieved ALL of your goals yesterday!";
			}
		  } else if (sameDayGiftCard) {
			message = "Congratulations! You've earned $20! Study staff will be sending it to you.";
		  } else if (lastGiftCard) {
			message = endOfStudyReward;
		  }
		}
	  
		if (message !== "") {
		  confetti.startConfetti(message, 2000);
		}
	  
		setJustCompletedSurvey(false);
	  }, [_weeklyData, dailyGoalsAchieved, sameDayGiftCard, lastGiftCard]);
	  

	if (loadingParticipantInfo || loadingWeeklyReport) {
		return null;
	}

	return (
		<Layout>
			<Card>
				<Rewards rewardsProfile={rewardsProfile} />
			</Card>
			<Card>
				{!participantCompletedStudy && <Streak streak={rewardsProfile.streak} />}
				{participantCompletedStudy && 
					<div className='pastUserDiv'>
						<div className="pastUserHeader margins">Thank you!</div>
						<div className="pastUserNormal margins">Study staff will send your remaining reward balance to you. Thanks for participating! The On-Track portion of the study is now complete. While the dashboard is still available to view, it will no longer be updated.</div>
					</div>
				}
			</Card>
			<Card>
				{_weeklyData && 
					<Accordion title="GOAL TRACKING" content={<WeeklyGoals weeklyData={_weeklyData}/>} 
						duration={_weeklyData.duration} title2="Weekly Goals"
						moveBackward={moveBackward} moveForward={moveForward}/>}
			</Card>
		</Layout>
	)
}