import React from 'react';
import "./WeeklyReportRow.css";
import Day from './Day';
import { IGoalWeeklyProgress } from './Home';

export default function (props: {weeklyProgress?: IGoalWeeklyProgress, weekDayNames? : boolean }) {
	return (
			<div>
			{!props.weekDayNames &&
				<div className="weeklyStatusRow">
					<div className="rowHeader">{props.weeklyProgress?.goalName}</div>
					<div className='weeklyStatusDays'>
						<Day success = {props.weeklyProgress?.progress[0] ?? "" }/>
						<Day success = {props.weeklyProgress?.progress[1] ?? "" } />
						<Day success = {props.weeklyProgress?.progress[2] ?? "" } />
						<Day success = {props.weeklyProgress?.progress[3] ?? "" } />
						<Day success = {props.weeklyProgress?.progress[4] ?? "" } />
						<Day success = {props.weeklyProgress?.progress[5] ?? "" } />
						<Day success = {props.weeklyProgress?.progress[6] ?? "" } />
					</div>
				</div>
			}
		 	{props.weekDayNames && 
				<div className="weeklyStatusRow">
					<div className="rowHeader"></div>
					<div className='weeklyStatusDays'>
						<Day success='false' text="MON" />
						<Day success='false' text="TUE" />
						<Day success='false' text="WED" />
						<Day success='false' text="THU" />
						<Day success='false' text="FRI" />
						<Day success='false' text="SAT" />
						<Day success='false' text="SUN" />
					</div>
				</div>}
			</div>
			
	)
}