import React, {useEffect, useState} from 'react';
import { IRewardsProfile } from './Home';
import "./Rewards.css";
import moment from 'moment';

export interface IDailyEarning { day: number, value: number };

export default function (props: { rewardsProfile : IRewardsProfile } ) {
    const _daysInStudy = 28;
    const _maxEarningPotential = 101.5;
    const _giftCardIncrement = 20;
    const _giftCardIncrementLast = 21.5;
    const _giftCard2Increment = 40;
    const _giftCard3Increment = 60;
    const _giftCard4Increment = 80;
    const isDay0 : boolean = props.rewardsProfile.daysInStudy === 0;
    const isPostDay28 : boolean = props.rewardsProfile.daysInStudy > _daysInStudy;
    const RewardSchedule : IDailyEarning[] =  [
            {day: 1, value: 1}, {day : 2, value : 1.5},
            {day : 3, value : 2}, {day : 4, value : 2.5}, 
            {day : 5, value : 3}, {day: 5, value: 3.5}, {day: 6, value: 4}
    ];

    var [daysNeeded, setDaysNeeded] = useState<number>(0);
    var [progress, setProgress] = useState<number[]>([0,0,0,0,0]);

    function initialize(){
        var useStreak = props.rewardsProfile.streak >= 6 ? 6 : Number(props.rewardsProfile.streak);
        var gcRemainder= props.rewardsProfile.totalEarned % 20;
        var leftToEarn = 20 - gcRemainder;
        var needed = 0;

        do {
            var rv = RewardSchedule.find( rs => rs.day === useStreak );
            if (!rv){
                break;
            }

            leftToEarn = leftToEarn - rv.value;
            needed = needed + 1;
            var nextStreak = useStreak + 1;
            useStreak = nextStreak >= 6 ? 6 : nextStreak;
        } while(leftToEarn > 0);

        setDaysNeeded(needed);
        let bubbles : number[] = [0,0,0,0,0];

        bubbles[0] = (props.rewardsProfile.totalEarned > _giftCardIncrement) ? _giftCardIncrement : props.rewardsProfile.totalEarned;
        if (props.rewardsProfile.totalEarned > _giftCardIncrement){
            bubbles[1] = (props.rewardsProfile.totalEarned >= _giftCard2Increment) ? _giftCardIncrement : (props.rewardsProfile.totalEarned - _giftCardIncrement);
        }

        if (props.rewardsProfile.totalEarned > _giftCard2Increment){
            bubbles[2] = (props.rewardsProfile.totalEarned >= _giftCard3Increment) ? _giftCardIncrement : (props.rewardsProfile.totalEarned - _giftCard2Increment);
        }

        if (props.rewardsProfile.totalEarned > _giftCard3Increment){
            bubbles[3] = (props.rewardsProfile.totalEarned >= _giftCard4Increment) ? _giftCardIncrement : (props.rewardsProfile.totalEarned - _giftCard3Increment);
        }

        if (props.rewardsProfile.totalEarned > _giftCard4Increment){
            bubbles[4] = (props.rewardsProfile.totalEarned >= _maxEarningPotential) ? _giftCardIncrementLast : (props.rewardsProfile.totalEarned - _giftCard4Increment);
        }
        
        setProgress(bubbles);
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <div className='rewardCardMargin'>
            {   !isDay0 && 
                <div>
                    <div className="rewardHeader margins">Rewards Earned</div>
                    <div className="rewardNormal margins">You will be sent your preferred reward for every $20 earned</div>
                    <div className="rewardNormal margins">You have earned: <span className= "aspireGreenFont rewardTotal" >$<span>{props.rewardsProfile.totalEarned.toFixed(2)}</span> Total</span></div>
                </div>
            }
            {   isDay0 && 
                <div>
                    <div className="rewardHeader margins">See you tomorrow!</div>
                    <div className="rewardNormal margins">Log back in tomorrow to start taking daily surveys and earning rewards</div>
                </div>
            }

             <div className='divRewardsProgress'>
                <div className='progress-wrapper'>
                    <progress className="progress-bar" max={_giftCardIncrement} value={progress[0]}></progress>
                    <div className='markerColumn'>
                        <div className={props.rewardsProfile.gcDate1 === undefined ? "divCircleNotAchieved" : "divCircleAchieved"}>{props.rewardsProfile.gcDate1 && <div className="rewardDate">{moment(props.rewardsProfile.gcDate1!).format('MM/DD')}</div>}</div>
                        <div className='rewardAccumulation'>$20</div>
                    </div>
                </div>
                <div className='progress-wrapper moveLeft'>
                    <progress className="progress-bar" max={_giftCardIncrement} value={progress[1]}></progress>
                    <div className='markerColumn'>
                        <div className={props.rewardsProfile.gcDate2 === undefined ? "divCircleNotAchieved" : "divCircleAchieved"}>{props.rewardsProfile.gcDate2 && <div className="rewardDate">{moment(props.rewardsProfile.gcDate2!).format('MM/DD')}</div>}</div>
                        <div className='rewardAccumulation'>$20</div>
                    </div>
                </div>
                <div className='progress-wrapper moveLeft'>
                    <progress className="progress-bar" max={_giftCardIncrement} value={progress[2]}></progress>
                    <div className='markerColumn'>
                        <div className={props.rewardsProfile.gcDate3 === undefined ? "divCircleNotAchieved" : "divCircleAchieved"}>{props.rewardsProfile.gcDate3 && <div className="rewardDate">{moment(props.rewardsProfile.gcDate3!).format('MM/DD')}</div>}</div>
                        <div className='rewardAccumulation'>$20</div>
                    </div>
                </div>
                <div className='progress-wrapper moveLeft'>
                    <progress className="progress-bar" max={_giftCardIncrement} value={progress[3]}></progress>
                    <div className='markerColumn'>
                        <div className={props.rewardsProfile.gcDate4 === undefined ? "divCircleNotAchieved" : "divCircleAchieved"}>{props.rewardsProfile.gcDate4 && <div className="rewardDate">{moment(props.rewardsProfile.gcDate4!).format('MM/DD')}</div>}</div>
                        <div className='rewardAccumulation'>$20</div>
                    </div>
                </div>
                <div className='progress-wrapper moveLeft'>
                    <progress className="progress-bar" max="21.5" value={progress[4]}></progress>
                    <div className='markerColumn'>
                        <div className={props.rewardsProfile.gcDate5 === undefined ? "divCircleNotAchieved" : "divCircleAchieved"}>{props.rewardsProfile.gcDate5 && <div className="rewardDate">{moment(props.rewardsProfile.gcDate5!).format('MM/DD')}</div>}</div>
                        <div className='rewardAccumulation'>Remaining</div>
                    </div>
                </div>
            </div>
            { !isDay0 && !isPostDay28 && 
                <div className="rewardNormal margins">Check in <span className= "aspireGreenFont">{daysNeeded}</span> days in a row to be sent your next reward!</div>
            }
            { (isDay0 || isPostDay28) &&
                <div className="rewardNormal margins"></div>
            }
        </div>
	)
}