import React from 'react';
import WeeklyReportRow from './WeeklyReportRow';
import "./WeeklyGoals.css";
import { IWeeklyReport } from './Home';

export default function ( props: {weeklyData: IWeeklyReport}  ) {
	
	return (
		<div className='weeklyGoalColumn' >
			<WeeklyReportRow weeklyProgress={props.weeklyData.alcoholProgress} />
			<WeeklyReportRow weeklyProgress={props.weeklyData.tobaccoProgress} />
			<WeeklyReportRow weeklyProgress={props.weeklyData.substanceProgress} />
			<WeeklyReportRow weeklyProgress={props.weeklyData.personalProgress} />
			<WeeklyReportRow weekDayNames={true}/>
			<div className="weeklyGoalRow lastColumn">
				<div className ="legendRow"><div className="circle aspire-green" /><div className="aspire-green-font">Goal</div></div>
				<div className ="legendRow"><div className="circle aspire-red" /><div className="aspire-red-font">Over Goal</div></div>
				<div className ="legendRow"><div className="circle aspire-dark-gray" /><div className="aspire-dark-gray-font">Not Logged</div></div>
			</div>
		</div>
	)
}