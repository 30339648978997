import { DateTime } from "luxon";
import type {
    Guid,
    SurveyAnswer,
    SurveyAnswersQuery,
    SurveyAnswersPage,
} from "@careevolution/mydatahelps-js";
import MyDataHelps from "@careevolution/mydatahelps-js";

export class DataAccess {

    constructor() {
    }

    public async getSurveyAnswers(surveyName: string, minDate?: DateTime, maxDate?: DateTime, stepIdentifiers?: string): Promise<SurveyAnswer[]> {
        
        let dataPage = await this.getSurveyDataPage(surveyName, minDate, maxDate);
        let allData = dataPage.surveyAnswers;
        while (dataPage.nextPageID) {
            dataPage = await this.getSurveyDataPage(surveyName, minDate, maxDate, stepIdentifiers, dataPage.nextPageID);
            allData = allData.concat(dataPage.surveyAnswers);
        }
        return allData;
    }

    private async getSurveyDataPage(surveyName: string, minDate?: DateTime, maxDate?: DateTime, stepIdentifiers?: string, pageID?: Guid): Promise<SurveyAnswersPage> {
        var queryParameters: SurveyAnswersQuery = {
            surveyName 
        };
        
        if (stepIdentifiers){
            //TODO: This does not work, not sure why...yet
            //queryParameters.stepIdentifier = stepIdentifiers;
        }

        if (minDate) {
            queryParameters.after = minDate.toISO();
        }

        if (maxDate) {
            queryParameters.before = maxDate.toISO();
        }

        if (pageID) {
            queryParameters.pageID = pageID;
        }

        return MyDataHelps.querySurveyAnswers(queryParameters)
    }
}