import React from 'react';
import "./Day.css";

export default function (props: {success: string, text? : string} ) {
	return (
		<div>
			{!props.text && 
				<div className={props.success === "true" ? "greenBox box" : (props.success === "false" ? "redBox box" : "none box")}>
					<div style={{ display: props.success === "true" ? 'inline' : 'none'}}><img src={"images/check.svg"} alt="Goal Achieved" /></div>
					<div style={{ display: props.success === "false" ? 'inline' : 'none'}}><img src={"images/x.svg"} alt="Goal Not Achieved"/></div>
				</div>
			}
			{props.text && 
				<div className="weekdayText">{props.text}</div>
			}
		</div>
		
	)
}