import React, {useEffect, useState} from 'react';
import MyDataHelps from '@careevolution/mydatahelps-js';
import * as Model from "@careevolution/mydatahelps-js/types";
import { DataAccess } from "../helpers/data";
import { DateTime } from "luxon";
import { Card, Layout, LoadingIndicator } from '@careevolution/mydatahelps-ui';
import ChartAccordion from './ChartAccordian';
import { debounce } from "lodash";
import {IDateFilter, loadStudyDurationWeeks, loadStudyDuration, isDayInRange, calcDaysInStudy} from "../helpers/studyDurationHelpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import "./MyData.css";

let dataAccess = new DataAccess();

export enum FilterDateType {
    Week = "Week",
    Month = "Month"
}

export enum FilterDataType {
    Alcohol = "Alcohol",
    Tobacco = "Tobacco",
    Substance = "Substance",
    Mood = "Mood",
    Stress = "Stress",
    Pain = "Pain",
    WellBeing = "WellBeing"
}

export interface IGoals {
    alcoholGoal : number,
    tobaccoGoal : number, 
    substanceGoal : number
}

export interface IDataPoint {
    dpType: FilterDataType,
    dpDate: Date,
    dpDateDisplay: string,
    dpValue?: number
}

export interface iDataReport {
    dataType: FilterDataType,
    dataGoal: number,
    weekDateFilter : IDateFilter,
    weekData : IDataPoint[],
    monthDateFilter : IDateFilter,
    monthData : IDataPoint[],
}

export interface iMultiLineDataReport {
    dataType: FilterDataType,
    weekDateFilter : IDateFilter,
    weekData : iWellBeingModel[],
    monthDateFilter : IDateFilter,
    monthData : iWellBeingModel[],
}

export interface iSurveyData {
    alcoholReport : IDataPoint[],
    tobaccoReport : IDataPoint[],
    substanceReport : IDataPoint[],
    wellBeingReport : iWellBeingModel[]
}

export interface iWellBeingModel{
    moodValue? : number,
    painValue? : number,
    stressValue? : number,
    dpDate: Date,
    dpDateDisplay: string
}

export default function MyData() {
    const _daysInMonth : number = 28; //the study is 28 days in length. This is the 28 days
    const _daysInWeek : number = 7;
	const _dailyMeasuresSurveyName : string = "ASPIRE-2 Daily Measures";
    const _AlcoholUse : string = "AlcoholUse";
    const _TobaccoUse : string = "TobaccoUse";
    const _SubstanceUse : string = "SubstanceUse";
    const _Mood : string = "Mood";
    const _Stress : string = "Stress";
    const _Pain : string = "Pain";
    const _stepIdentifiers : string = `${_AlcoholUse},${_TobaccoUse},${_SubstanceUse},${_Mood},${_Stress},${_Pain}`;

    const [loadingParticipantInfo, setLoadingParticipantInfo] = useState(true);
    const [loadingSurveyData, setLoadingSurveyData] = useState(true);
    const [dateFilter, setDateFilter] = useState<any>(FilterDateType.Week);
    const [participantDuration, setParticipantDuration] = useState<IDateFilter>();

    const [alcoholReport, setAlcoholReport] = useState<iDataReport>({dataType : FilterDataType.Alcohol, dataGoal: 0, weekDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, weekData: [], monthDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, monthData: []});
    const [tobaccoReport, setTobaccoReport] = useState<iDataReport>({dataType : FilterDataType.Alcohol, dataGoal: 0, weekDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, weekData: [], monthDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, monthData: []});
    const [substanceReport, setSubstanceReport] = useState<iDataReport>({dataType : FilterDataType.Alcohol, dataGoal: 0, weekDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, weekData: [], monthDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, monthData: []});
    const [wellBeingReport, setWellBeingReport] = useState<iMultiLineDataReport>({dataType : FilterDataType.WellBeing, weekDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, weekData: [], monthDateFilter: {startDate : DateTime.now(), endDate: DateTime.now()}, monthData: []});

    async function initialize(){
        const participantData = await loadParticipant();
        const surveyData = await dataAccess.getSurveyAnswers(_dailyMeasuresSurveyName, participantData.minDate, participantData.maxDate, _stepIdentifiers);
        loadData(surveyData, participantData.weekFilters, participantData.monthFilters, participantData.goals);
    }

    async function loadParticipant(): Promise<{minDate: DateTime, maxDate: DateTime, weekFilters : IDateFilter, monthFilters : IDateFilter, goals : IGoals}>{
        setLoadingParticipantInfo(true);

        const participantInfo = await MyDataHelps.getParticipantInfo();
        let customFields = participantInfo.customFields;
        var enrollmentDate = participantInfo.enrollmentDate;
		var daysInStudy : number = calcDaysInStudy(DateTime.fromISO(enrollmentDate));

        var alcoholGoal : number = customFields.hasOwnProperty('AlcoholGoal') && customFields['AlcoholGoal'].trim().length > 0 ? Number(customFields['AlcoholGoal']) : 0;
        var tobaccoGoal : number = customFields.hasOwnProperty('TobaccoGoal') && customFields['TobaccoGoal'].trim().length > 0 ? Number(customFields['TobaccoGoal']) : 0;
        var substanceGoal : number = customFields.hasOwnProperty('SubstanceGoal') && customFields['SubstanceGoal'].trim().length > 0 ? Number(customFields['SubstanceGoal']) : 0;
        const particpantGoals : IGoals = {alcoholGoal : alcoholGoal, tobaccoGoal : tobaccoGoal, substanceGoal: substanceGoal};

        var durations = loadStudyDurationWeeks(daysInStudy);

        setParticipantDuration(loadStudyDuration(enrollmentDate));
        setLoadingParticipantInfo(false);

         var currentOrMostRecentWeekInStudy = setActiveStudyWeekDuration(durations);

        return {minDate: durations.startDate, maxDate: durations.endDate, weekFilters: currentOrMostRecentWeekInStudy, monthFilters: durations, goals : particpantGoals};
    }

    function setActiveStudyWeekDuration(studyDuration : IDateFilter) : IDateFilter {
        const _otherDaysInWeek : number = 6;
        const today = DateTime.now().startOf("day");
        var startDate = studyDuration.startDate;
        var studyWeeks = Math.abs(Math.floor(studyDuration.startDate.diff(studyDuration.endDate, "weeks").weeks));
        var endDate = startDate.plus({'days' : _otherDaysInWeek}).endOf("day");
        let searchWeek : IDateFilter =  {startDate: startDate, endDate: endDate};

        for( var xx : number = 0; xx < studyWeeks; xx++){
            searchWeek = {startDate: startDate, endDate: endDate};
            if (isDayInRange(today, startDate, endDate)){
                break;
            }
            startDate = endDate.plus({'days' : 1}).startOf("day");
            endDate = startDate.plus({'days' : _otherDaysInWeek}).endOf("day");
        }

        return searchWeek;
    }

    function isWeekData(surveyAnswer: Model.SurveyAnswer, weekDateFilters : IDateFilter) {
        var surveyDate : DateTime = DateTime.fromISO(surveyAnswer.date);
        return surveyDate >= weekDateFilters.startDate && surveyDate <= weekDateFilters.endDate;
    }

    function isMonthData(surveyAnswer: Model.SurveyAnswer, monthDateFilters : IDateFilter) {
        var surveyDate : DateTime = DateTime.fromISO(surveyAnswer.date);
        return surveyDate >= monthDateFilters.startDate && surveyDate <= monthDateFilters.endDate;
    }

    function loadData(surveyAnswers : Model.SurveyAnswer[], weekDateFilters : IDateFilter, monthDateFilters : IDateFilter, goals : IGoals){
        setLoadingSurveyData(true);

        var weekDataPoints = surveyAnswers.filter(s => isWeekData(s, weekDateFilters));
        var monthDataPoints = surveyAnswers.filter(s => isMonthData(s, monthDateFilters));

        var weeklyReport : iSurveyData = loadDataByDays(weekDateFilters.startDate, _daysInWeek, weekDataPoints);
        var monthlyReport : iSurveyData = loadDataByDays(monthDateFilters.startDate, _daysInMonth, monthDataPoints);

        setAlcoholReport( {
            dataType: FilterDataType.Alcohol,
            dataGoal: goals.alcoholGoal,
            weekDateFilter :weekDateFilters,
            weekData : weeklyReport.alcoholReport,
            monthDateFilter: monthDateFilters,
            monthData : monthlyReport.alcoholReport
        });

        setTobaccoReport( {
            dataType: FilterDataType.Tobacco,
            dataGoal : goals.tobaccoGoal,
            weekDateFilter :weekDateFilters,
            weekData : weeklyReport.tobaccoReport,
            monthDateFilter: monthDateFilters,
            monthData : monthlyReport.tobaccoReport
        });

        setSubstanceReport( {
            dataType: FilterDataType.Substance,
            dataGoal : goals.substanceGoal,
            weekDateFilter :weekDateFilters,
            weekData : weeklyReport.substanceReport,
            monthDateFilter: monthDateFilters,
            monthData : monthlyReport.substanceReport
        });

        setWellBeingReport({
            dataType: FilterDataType.WellBeing,
            weekDateFilter :weekDateFilters,
            weekData : weeklyReport.wellBeingReport,
            monthDateFilter: monthDateFilters,
            monthData : monthlyReport.wellBeingReport
        });

        setLoadingSurveyData(false);
    }

    function getAnswerForDate( answer: Model.SurveyAnswer, dateInContext : DateTime){
        const answerDate = DateTime.fromISO(answer.date).startOf('day');
        const diffInDays = answerDate.diff(dateInContext, 'days');
        const diffObject = diffInDays.toObject();
        return diffObject.days === 0;
    }

    function getDataPointForDay(responses: Model.SurveyAnswer[],  dateInContext : DateTime, dataType : FilterDataType){
        var response = responses.find(a => getAnswerForDate( a, dateInContext));
        if (!response) {
            return createDatePoint(dataType, dateInContext, undefined);
        } else {
            return createDatePoint(dataType, dateInContext, response.answers[0]);
        }
    }

    function loadDataByDays(startDate : DateTime, numberOfDates : number, surveyData : Model.SurveyAnswer[]){
        var dateInContext = startDate.startOf('day');
        var alcoholReport: IDataPoint[] = [];
        var tobaccoReport: IDataPoint[] = [];
        var substanceReport: IDataPoint[] = [];
        var wellBeingReport : iWellBeingModel[] = [];

        var alcoholResponses = surveyData.filter(a => a.stepIdentifier === _AlcoholUse && a.resultIdentifier === _AlcoholUse);
        var tobaccoResponses = surveyData.filter(a => a.stepIdentifier === _TobaccoUse && a.resultIdentifier === _TobaccoUse);
        var substanceResponses = surveyData.filter(a => a.stepIdentifier === _SubstanceUse && a.resultIdentifier === _SubstanceUse);
        var moodResponses = surveyData.filter(a => a.stepIdentifier === _Mood && a.resultIdentifier === _Mood);
        var stressResponses = surveyData.filter(a => a.stepIdentifier === _Stress && a.resultIdentifier === _Stress);
        var painResponses = surveyData.filter(a => a.stepIdentifier === _Pain && a.resultIdentifier === _Pain);

        for (let i = 0; i < numberOfDates; i++) {
            var alcDataPoint = getDataPointForDay(alcoholResponses, dateInContext, FilterDataType.Alcohol);
            var tobDataPoint = getDataPointForDay(tobaccoResponses, dateInContext, FilterDataType.Tobacco);
            var subDataPoint = getDataPointForDay(substanceResponses, dateInContext, FilterDataType.Substance);
            var moodDataPoint = getDataPointForDay(moodResponses, dateInContext, FilterDataType.Mood);
            var stressDataPoint = getDataPointForDay(stressResponses, dateInContext, FilterDataType.Stress);
            var painDataPoint = getDataPointForDay(painResponses, dateInContext, FilterDataType.Pain);

            var wellBeingModel : iWellBeingModel = { 
                moodValue : moodDataPoint.dpValue, 
                painValue : invertValueOnRange(painDataPoint.dpValue), 
                stressValue : invertValueOnRange(stressDataPoint.dpValue), 
                dpDate: dateInContext.toJSDate(),
                dpDateDisplay: dateInContext.toLocaleString({ month: 'short', day: 'numeric' })
            };

            alcoholReport.push(alcDataPoint);
            tobaccoReport.push(tobDataPoint);
            substanceReport.push(subDataPoint);
            wellBeingReport.push(wellBeingModel);
            dateInContext = dateInContext.plus({ days: 1});
        }

        return {
            alcoholReport : alcoholReport,
            tobaccoReport : tobaccoReport,
            substanceReport : substanceReport,
            wellBeingReport : wellBeingReport,
        }
    }

    function invertValueOnRange(dataValue? : number){
        const maxOfRange : number = 10;
        return dataValue !== undefined ? (maxOfRange - dataValue) : dataValue;
    }

    function createDatePoint(answerType: FilterDataType, answerDate: DateTime, answerValue?: string ){
        if (!answerValue){
            return {dpType: answerType, dpDate: answerDate.toJSDate(), dpDateDisplay: answerDate.toLocaleString({ month: 'short', day: 'numeric' })}
        } else
        {
            return {dpType: answerType, dpDate: answerDate.toJSDate(), dpDateDisplay: answerDate.toLocaleString({ month: 'short', day: 'numeric' }),  dpValue: answerValue ? parseInt(answerValue) : 0 }
        }
    }

    useEffect(() => {
		let debouncedInitialize = debounce(initialize, 500);

		debouncedInitialize();

		MyDataHelps.on("applicationDidBecomeVisible", debouncedInitialize);
		
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", debouncedInitialize);
		} 
	}, []);

	if (loadingParticipantInfo || loadingSurveyData) {
		return <LoadingIndicator/>;
	}

	return (
        <Layout>
            <Card>
                <div className='dateFilterRow filterMargins'>
                    {alcoholReport && dateFilter===FilterDateType.Week &&
                        <div>
                            <div className='bold'>Current Week</div>
                            <div className='aspireFont'>{`${alcoholReport.weekDateFilter.startDate.toLocaleString({ month: 'short', day: 'numeric' })} - ${alcoholReport.weekDateFilter.endDate.toLocaleString({ month: 'short', day: 'numeric' })}`}</div>
                        </div>
                    }
                    {participantDuration && dateFilter===FilterDateType.Month &&
                        <div>
                            <div className='bold'>All Time</div>
                            <div className='aspireFont'>{`${participantDuration.startDate.toLocaleString({ month: 'short', day: 'numeric' })} - ${participantDuration.endDate.toLocaleString({ month: 'short', day: 'numeric' })}`}</div>
                        </div>
                    }
                    <div className='dataViewToggle' onClick={() => setDateFilter(dateFilter===FilterDateType.Week ? FilterDateType.Month : FilterDateType.Week)}>
                        <FontAwesomeIcon icon={ dateFilter===FilterDateType.Week? faAngleUp : faAngleDown}/>
                        <div style={{ display: dateFilter===FilterDateType.Week ? 'inline' : 'none'}}>Week</div>
                        <div style={{ display: dateFilter===FilterDateType.Month ? 'inline' : 'none'}}>All Time</div>
                    </div>
                </div>
            </Card>
            <Card>
                {alcoholReport && <ChartAccordion singleLineData={alcoholReport} title="Alcohol Use" dateFilter={dateFilter}/>}
            </Card>
            <Card>
                {tobaccoReport && <ChartAccordion singleLineData={tobaccoReport} title="Tobacco Use" dateFilter={dateFilter}/>}
            </Card>
            <Card>
                {substanceReport && <ChartAccordion singleLineData={substanceReport} title="Substance Use" dateFilter={dateFilter}/>}
            </Card>
            <Card>
                {wellBeingReport && <ChartAccordion multiLineData={wellBeingReport} title="Mood, Stress, Pain" dateFilter={dateFilter}/>}
            </Card>
        </Layout>
    ) 
}
