import React, {useState} from 'react';
import './Faq.css';
import { Layout, Card } from "@careevolution/mydatahelps-ui"

interface IStyledDetail {
    summaryText: string;
    detailText: string;
}
interface IStyledDetailArray {
    array: IStyledDetail[];
}
function StyledDetail(props: IStyledDetail) {
    return (
        <details>
            <summary>
                <span className="summary-title">{props.summaryText}</span>
                <div className="summary-chevron-up">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" 
                    strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down">
                    <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </div>
            </summary>
            <div className="summary-content" dangerouslySetInnerHTML={{__html: props.detailText}}></div>
            <div className="summary-chevron-down" />
        </details>
    )
}


export default function Faq() {
    const [faqText] = useState<IStyledDetailArray>(
		{
            array: [
                {
                    summaryText: "What is the difference between MyDataHelps™ and On-Track?",
                    detailText: `The A2 Study uses a research engagement platform called “MyDataHelps™” to host On-Track. When you log in to the MyDataHelps app or website you will connect directly to the On-Track tool for the A2 study.`
                   
                },
                {
                    summaryText: "What is the purpose of On-Track?",
                    detailText: `On-Track is designed to help you monitor your own health and well-being. On-Track will also assess your personal goals and provide feedback about your progress. Therefore, the purpose of On-Track is to help you learn more about yourself and make desired behavior changes through answering questions each day, and viewing graphs personalized to you and your goals, all while making money!
                    <p>Please see this video walkthrough of On-Track for more information:</p>

                    <a href="https://www.youtube.com/watch?v=aJUGoZieNEU" target="blank">Video walkthrough of On-Track features</a>`
                },
                {
                    summaryText: "What is being asked of me?",
                    detailText: `We ask that you log in to On-Track each day for 4 weeks (28 days) and answer questions about your behavior and well-being. You can then view your goals and progress. On most days, you will be asked 7 questions.
                    <p>Please see this video walkthrough of On-Track for more information:</p>
                    <a href="https://www.youtube.com/watch?v=aJUGoZieNEU" target="blank">Video walkthrough of On-Track features</a>`
                },
                {
                    summaryText: "What features are included within this app, and how would I access them?",
                    detailText: `Every day when you access On-track you will be asked a short series of questions and have opportunities to change your daily goals if desired. You will be able to access all other features once you have answered your survey questions for that day. On-Track includes the following features:
                    <ul>
                        <li>Personalized daily goal setting</li>
                        <li>Graphs detailing your mood, stress, and pain levels, as well as your substance use</li>
                        <li>Weekly charts detailing how often you achieve your goals</li>
                        <li>Resources within Michigan and the Ann Arbor area that support mental health and wellness</li>
                    </ul>
                    <p>Please see this video walkthrough of On-Track for more information:</p>
                    <a href="https://www.youtube.com/watch?v=aJUGoZieNEU" target="blank">Video walkthrough of On-Track features</a>
`
                },
                
                
                {
                    summaryText: "Who can I contact if I have any specific questions or concerns regarding my involvement with On-Track?",
                    detailText: `For any specific questions or concerns, we encourage you to please email our team at <a href="mailto:A2-Research-Study@med.umich.edu">A2-Research-Study@med.umich.edu</a>. Please type “On-Track” in your email's subject line before sending.`
                },
                {
                    summaryText: "Why do I earn different amounts of money on different days?",
                    detailText: "The money you earn is rewarded on an escalating incentive schedule. You earn more money by completing all survey items for multiple days in a row. In detail, you will begin by earning $1.00 for completing all survey items on day one. If you complete all survey items on day two, you will earn $1.50. If you complete all items on day three, you will earn $2.00. These $.50 increments will increase until you have reached $4.00. From there, if you continue to complete all survey items for each day, you will continue to earn $4.00 per day."
                },
                {
                    summaryText: "How does missing a day affect how much I am paid when answering items?",
                    detailText: "If you miss a day, the next time you log in and complete all survey items you will earn $1.00. The $.50 escalation described in the question above will then continue again."
                },
                {
                    summaryText: "Does missing a day prevent me from participating further?",
                    detailText: `Missing a day <b>does not</b> prevent you from being able to earn money the following day or any day thereafter until the 28 days have passed.`
                },
                {
                    summaryText: "When and how will I receive the money I have earned?",
                    detailText: "You will receive your monetary incentive every time you earn $20. If you drop out of our study while enrolled, or if you finish On-Track before earning $20, you will be sent the remaining balance of what you have earned. Payments will be sent to you either as a virtual gift card via email, or as a check mailed to the address you provided. How you will receive your money is based on the selection you chose upon enrollment into our study. You are not allowed to change how you will receive your monetary reward."
                },
                {
                    summaryText: "Can I still earn money for a day in which I did not complete all survey items?",
                    detailText: "No. To earn money for that day, you must answer all the survey items presented for that day."
                },
                {
                    summaryText: "How do I view how much money I have earned?",
                    detailText: "Click the “Home” tab (i.e. “Dashboard”). At the top of your screen, you will see a tracker detailing how much money you have earned in total."
                },
                {
                    summaryText: "How do I disable the App “push notifications” from my device?",
                    detailText: "You can choose to disable notifications in the settings on your mobile device."
                },
                {
                    summaryText: "How do I change my answers to questions?",
                    detailText: "You can change your answers at any time throughout the survey by pressing the blue arrow at the top of your screen. However, once you submit your survey, you will be unable to change your answers for that day."
                },
                {
                    summaryText: "Where can I view and change my goals?",
                    detailText: "To view your goals, click on the “My Data” tab. Your goal for each substance is represented by a gold line. To change your goals, select “yes” when asked “would you like to change your daily goal” in each daily survey."
                },
                {
                    summaryText: "Are my answers to these questions confidential?",
                    detailText: "The information that we collect from you will be stripped of identifiers (meaning any of your personal information, such as name or email, will be removed). You may be concerned about the privacy of your answers. Research records will be kept in a separate research file that does not include information that is likely to allow someone other than the researchers to link the information to you. All computer data files will be saved with passwords. We will not allow anyone outside of the study team to see your research record."
                },
                {
                    summaryText: "How do I opt out of daily text or email notifications?",
                    detailText: `Please contact the A2 Study team at A2-Research-Study@med.umich.edu to unsubscribe from daily email and/or text reminders. Please type “On-Track” in your email's subject line before sending.`
                },
                {
                    summaryText: "How are my answers protected, and my data secured?",
                    detailText: `CareEvolution uses appropriate physical, organizational, and technical safeguards designed to protect the confidentiality, integrity, and availability of the Data collected. For example, your data is encrypted both while it is stored and while it is transmitted in accordance with the security standards set forth by the National Institute of Standards and Technology’s (NIST) Federal Information Processing Standard (FIPS) Publication 140-2: Security Requirements for Cryptographic Modules. These are the standards mandated by the Department of Health and Human Services for securing health information. 
                    CareEvolution cannot, however, fully guarantee the security of the Data or any information transmitted to us. For more information on how CareEvolution secures your data, please <a href="https://trust.careevolution.com/mydatahelps/privacy.html">click here</a>.`
                },
                {
                    summaryText: "Is MyDataHelps™ HIPAA compliant?",
                    detailText: "The MyDataHelps™ platform is built on top of the CareEvolution HIEBus platform, which has been securely handling protected health information (PHI) for over 15 years for hospitals, insurance companies, and other organizations. HIEBus is HIPAA compliant and Meaningful Use Certified. Role-based access control ensures that participant data is only exposed to individuals with the appropriate permissions."
                },
                {
                    summaryText: "Will this online platform access personal information on my device(s)?",
                    detailText: "The online platform will not access your personal contacts, personal photos nor any text and email messages on your smartphone without your explicit permission. Our research study will never ask for permission to obtain any personal information from any device you use to access the online platform."
                },
            ]
        }
	);

	return (
		<Layout>
			<Card>
                <h1 className="FaqMainHeader">FAQ</h1>
                {
                    faqText.array.map((faq: IStyledDetail) => (<StyledDetail key={faq.summaryText} 
                        summaryText={faq.summaryText} detailText={faq.detailText} />))    
                }
			</Card>
            <Card>
				<h1 className="FaqResourceHeader">Community Health Resources</h1>
                    <h2 className="FaqResourceText"><u>Activity Center - Ann Arbor Community Center</u></h2>
                        <p className="FaqResourceDetail">625 N. Main St.; 734-662-3128</p> 
                        <p className="FaqResourceDetail"><a href="http://www.annarbor-communitycenter.org">www.annarbor-communitycenter.org</a></p>
                    <h2 className="FaqResourceText"><u>Free Health Clinic - Packard Health</u></h2>
                        <p className="FaqResourceDetail">2650 Carpenter Rd., Ann Arbor, MI 48197; 734-971-1073</p> 
                        <p className="FaqResourceDetail"><a href="http://www.packardhealth.org">www.packardhealth.org</a></p>
                        <p className="FaqResourceDetail">M-F: (8:00am - 5:00pm); Sat: (8:00am - Noon)</p>
                    <h2 className="FaqResourceText"><u>Alcohol and Drug Abuse Hotline</u></h2>
                        <p className="FaqResourceDetail">1-866-675-4912 (24/7)</p> 
                    <h2 className="FaqResourceText"><u>Washtenaw County Mental Health and Substance Abuse Services</u></h2>
                        <p className="FaqResourceDetail">1-800-440-7548 (24/7 hotline); Ann Arbor: 734-544-3050</p> 
                        <p className="FaqResourceDetail">2140 E. Ellsworth Rd. (M-F 8:30am - 5:00pm)</p>
                    <h2 className="FaqResourceText"><u>Transportation Services - Ann Arbor Transportation Authority (AATA)</u></h2>
                        <p className="FaqResourceDetail">734-996-0400 (Ann Arbor &amp; Ypsilanti Area)</p>
                        <p className="FaqResourceDetail">(M-F: 6am-10pm; Sat-Sun: 7am-7pm)</p> 
                        <p className="FaqResourceDetail"><a href="http://www.theride.org">Theride.org</a></p>
                    <h2 className="FaqResourceText"><u>Suicide &amp; Crisis Lifeline</u></h2>
                        <p className="FaqResourceDetail">Dial 9-8-8; To message - Text 838255</p> 
                        <p className="FaqResourceDetail"><a href="http://www.988lifeline.org">988lifeline.org</a></p>
                    <h2 className="FaqResourceText"><u>Peer Support Specialist Warmline</u></h2>
                        <p className="FaqResourceDetail">888-733-7753 (10am-2am, daily)</p> 
                    <h2 className="FaqResourceText"><u>Michigan Crisis and Access Line (MiCAL)</u></h2>
                        <p className="FaqResourceDetail">State-wide resources:</p> 
                        <p className="FaqResourceDetail"><a href="http://mical.michigan.gov/s/resources">mical.michigan.gov/s/resources</a></p>
                    <h2 className="FaqResourceText"><u>Psychiatric Emergency Services</u></h2>
                        <p className="FaqResourceDetail">734-936-5900 (24/7)</p> 
                        <p className="FaqResourceDetail">1500 E. Medical Center Drive, Floor B1, Room A240</p>
                    <h2 className="FaqResourceText"><u>For help with community resources</u> (e.g. food, housing, utilities)</h2>
                        <p className="FaqResourceDetail">Dial 2-1-1 (180+ languages)</p> 
                        <p className="FaqResourceDetail"><a href="http://www.mi211.org">mi211.org</a></p>
            </Card>
		</Layout>
	)
}