import { DateTime } from "luxon";

export interface IDateFilter {
    startDate: DateTime,
    endDate: DateTime
}

export function loadStudyDurationWeeks(zeroBasedDaysInStudy : number)
{
    //daysInStudy is 0 based. Adjust to avoid going back to enrollment date as day 1
    const daysInStudy = zeroBasedDaysInStudy - 1;
    const _daysInStudyNotIncludingToday : number = 27;

    const studyStartDate = DateTime.now().startOf('day').minus({days: daysInStudy});
    const studyStartMonday = getClosestMonday(studyStartDate);

    const studyEndDate = studyStartDate.plus({days: _daysInStudyNotIncludingToday}).endOf('day');
    const lastMondayFromEndDate = getClosestMonday(studyEndDate);
    const studyEndOfSunday = lastMondayFromEndDate.plus({days: 6}).endOf('day');

    const studyDurationFilters = { startDate: studyStartMonday, endDate: studyEndOfSunday };
    return studyDurationFilters;
};


export function loadStudyDuration(enrollmentDate : string)
{
    var zeroBasedDaysInStudy : number = calcDaysInStudy(DateTime.fromISO(enrollmentDate));

    //daysInStudy is 0 based. Adjust to avoid going back to enrollment date as day 1
    const daysInStudy = zeroBasedDaysInStudy - 1;
    const _daysInStudyNotIncludingToday : number = 27;

    const studyStartDate = DateTime.now().startOf('day').minus({days: daysInStudy});
    const studyEndDate = studyStartDate.plus({days: _daysInStudyNotIncludingToday}).endOf('day');
  
    const studyDurationFilters = { startDate: studyStartDate, endDate: studyEndDate };
    return studyDurationFilters;
};

export function getClosestMonday(fromDate?: DateTime) {
    const today = !fromDate ? DateTime.now() : fromDate;
    const daysToSubtract: number = (today.weekday + 6) % 7;
    const monday = today.minus({ days: daysToSubtract }).startOf("day");
    return monday;
};

export function sameDay(d1: DateTime, d2?: DateTime) {
    if (d2 === undefined ) {
        return false;
    }

    var daysDiff = d1.diff(d2, 'days');
    return daysDiff.days === 0;
};

export function calcDaysInStudy(enrollmentDate: DateTime){
    //this code should remain in synch with the one in the web viz that calculates rewards.
    const currentDate = DateTime.now().startOf('day');
    var studyDay1 = enrollmentDate.startOf('day').plus({'days' : 1});
    var diff  =  currentDate.diff(studyDay1, 'days');
    return diff.days < 0 ? 0 : diff.days + 1;
};

export function isDayInRange(dateToCheck : DateTime, startDate : DateTime, endDate : DateTime){
    return (dateToCheck >= startDate && dateToCheck <= endDate )
};
