import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';
import { iWellBeingModel } from './MyData';
import './SingleLineChart.css';

  const Y0 = ( props : any) => {
    const {x, y} = props;

    return (
      <path
        d="M11 0C4.92339 0 0 4.92339 0 11C0 17.0766 4.92339 22 11 22C17.0766 22 22 17.0766 22 11C22 4.92339 17.0766 0 11 0ZM12.4992 8.41411L16.0476 6.28508C16.5621 5.97903 17.1121 6.62661 16.7306 7.08347L15.2403 8.87097L16.7306 10.6585C17.1165 11.1198 16.5577 11.7585 16.0476 11.4569L12.4992 9.32782C12.1577 9.11935 12.1577 8.62258 12.4992 8.41411ZM5.26935 7.08347C4.8879 6.62661 5.4379 5.97903 5.95242 6.28508L9.50081 8.41411C9.84677 8.62258 9.84677 9.11935 9.50081 9.32782L5.95242 11.4569C5.44234 11.7585 4.8879 11.1198 5.26935 10.6585L6.75968 8.87097L5.26935 7.08347ZM11 12.4194C13.302 12.4194 16.1141 14.3621 16.4645 17.152C16.5399 17.7552 16.1097 18.2431 15.6794 18.0569C14.5306 17.5645 12.823 17.2851 11 17.2851C9.17702 17.2851 7.46935 17.5645 6.32056 18.0569C5.88589 18.2431 5.46008 17.7464 5.53548 17.152C5.88589 14.3621 8.69798 12.4194 11 12.4194Z" 
        fill="#FF671D"
        id="worst"
        transform={`translate(${x - 25} ${y - 10})`}
      />
    );
  };

  const Y2_5 = ( props : any) => {
    const {x, y} = props;

    return (
      <path
        d="M11 0.5C4.92339 0.5 0 5.42339 0 11.5C0 17.5766 4.92339 22.5 11 22.5C17.0766 22.5 22 17.5766 22 11.5C22 5.42339 17.0766 0.5 11 0.5ZM14.5484 7.95161C15.3335 7.95161 15.9677 8.58589 15.9677 9.37097C15.9677 10.156 15.3335 10.7903 14.5484 10.7903C13.7633 10.7903 13.129 10.156 13.129 9.37097C13.129 8.58589 13.7633 7.95161 14.5484 7.95161ZM7.45161 7.95161C8.23669 7.95161 8.87097 8.58589 8.87097 9.37097C8.87097 10.156 8.23669 10.7903 7.45161 10.7903C6.66653 10.7903 6.03226 10.156 6.03226 9.37097C6.03226 8.58589 6.66653 7.95161 7.45161 7.95161ZM15.0008 17.6298C14.0073 16.4411 12.548 15.7581 11 15.7581C9.45202 15.7581 7.99274 16.4411 6.99919 17.6298C6.4004 18.3528 5.30927 17.4435 5.90806 16.7206C7.17218 15.2081 9.03065 14.3387 11 14.3387C12.9694 14.3387 14.8278 15.2081 16.0875 16.725C16.6907 17.4435 15.5996 18.3528 15.0008 17.6298Z" 
        fill="#FF942D"
        id="almostworst"
        transform={`translate(${x - 25} ${y - 10})`}
      />
    );
  };

  const Y5 = ( props : any) => {
    const {x, y} = props;

    return (
      <path
        d="M11 0C4.92339 0 0 5.14718 0 11.5C0 17.8528 4.92339 23 11 23C17.0766 23 22 17.8528 22 11.5C22 5.14718 17.0766 0 11 0ZM7.45161 7.79032C8.23669 7.79032 8.87097 8.45343 8.87097 9.27419C8.87097 10.095 8.23669 10.7581 7.45161 10.7581C6.66653 10.7581 6.03226 10.095 6.03226 9.27419C6.03226 8.45343 6.66653 7.79032 7.45161 7.79032ZM15.2581 16.6935H6.74194C5.80161 16.6935 5.80161 15.2097 6.74194 15.2097H15.2581C16.1984 15.2097 16.1984 16.6935 15.2581 16.6935ZM14.5484 10.7581C13.7633 10.7581 13.129 10.095 13.129 9.27419C13.129 8.45343 13.7633 7.79032 14.5484 7.79032C15.3335 7.79032 15.9677 8.45343 15.9677 9.27419C15.9677 10.095 15.3335 10.7581 14.5484 10.7581Z" 
        fill="#FFBF3C"
        id="decent"
        transform={`translate(${x - 25} ${y - 10})`}
      />
    );
  };

  const Y7_5 = ( props : any) => {
    const {x, y} = props;

    return (
      <path
        d="M11 0.5C4.92339 0.5 0 5.42339 0 11.5C0 17.5766 4.92339 22.5 11 22.5C17.0766 22.5 22 17.5766 22 11.5C22 5.42339 17.0766 0.5 11 0.5ZM14.5484 7.95161C15.3335 7.95161 15.9677 8.58589 15.9677 9.37097C15.9677 10.156 15.3335 10.7903 14.5484 10.7903C13.7633 10.7903 13.129 10.156 13.129 9.37097C13.129 8.58589 13.7633 7.95161 14.5484 7.95161ZM7.45161 7.95161C8.23669 7.95161 8.87097 8.58589 8.87097 9.37097C8.87097 10.156 8.23669 10.7903 7.45161 10.7903C6.66653 10.7903 6.03226 10.156 6.03226 9.37097C6.03226 8.58589 6.66653 7.95161 7.45161 7.95161ZM16.0919 15.5008C14.8278 17.0177 12.9738 17.8871 11 17.8871C9.02621 17.8871 7.17218 17.0177 5.90806 15.5008C5.30484 14.7778 6.39597 13.873 6.99919 14.5915C7.99274 15.7847 9.44758 16.4633 11 16.4633C12.5524 16.4633 14.0073 15.7802 15.0008 14.5915C15.5952 13.873 16.6907 14.7778 16.0919 15.5008Z" 
        fill="#7AB740"
        id="well"
        transform={`translate(${x - 25} ${y - 10})`}
      />
    );
  };

  const Y10 = ( props : any) => {
    const {x, y} = props;

    return (
      <path
        d="M11 0C4.92339 0 0 4.92339 0 11C0 17.0766 4.92339 22 11 22C17.0766 22 22 17.0766 22 11C22 4.92339 17.0766 0 11 0ZM12.0645 8.84436C12.2109 6.97702 13.4927 5.67742 14.5484 5.67742C15.604 5.67742 16.8859 6.97702 17.0323 8.84436C17.0633 9.22581 16.5532 9.37218 16.3714 9.04395L15.95 8.28992C15.6085 7.68226 15.0984 7.33186 14.5528 7.33186C14.0073 7.33186 13.4972 7.68226 13.1556 8.28992L12.7343 9.04395C12.548 9.37218 12.0335 9.22581 12.0645 8.84436ZM4.96774 8.84436C5.11411 6.97702 6.39597 5.67742 7.45161 5.67742C8.50726 5.67742 9.78911 6.97702 9.93548 8.84436C9.96653 9.22581 9.45645 9.37218 9.2746 9.04395L8.85323 8.28992C8.51169 7.68226 8.00161 7.33186 7.45605 7.33186C6.91048 7.33186 6.4004 7.68226 6.05887 8.28992L5.6375 9.04395C5.44677 9.37218 4.93669 9.22137 4.96774 8.84436ZM17.6931 13.2177C17.2984 16.3669 14.6105 18.8065 11.3548 18.8065H10.6452C7.38952 18.8065 4.70161 16.3669 4.30686 13.2177C4.25363 12.7964 4.58629 12.4194 5.0121 12.4194H16.9879C17.4137 12.4194 17.7464 12.7919 17.6931 13.2177Z" 
        fill="#01AF43"
        id="great"
        transform={`translate(${x - 25} ${y - 10})`}
      />
    );
  };

  const MultiLineChart = (props: { data? : iWellBeingModel[] } ) => {
    const showWeekDay = props.data ? ( props.data.length ===7 ? true : false ) : true;
    const legendFormatter = (value: string, entry: any) => {
    var legendText = value.replace("Value", "");
      return legendText.charAt(0).toUpperCase() + legendText.slice(1);
    };

    const XAxisTick = (value : any) => {
      const date = new Date(value)
      // Check for monthly/weekly chart
      if (!showWeekDay) {
        return date.toLocaleString('en-us', { month: 'short', day: 'numeric' });
      }
      return date.toLocaleDateString('en-us', {weekday: 'short'});
    }

    const YAxisTick = (props: {payload : {value: any}, x: number, y:number}) => {
      switch(props.payload.value) {
        case 10:
          return <Y10 {...props} />
        case 7.5:
          return <Y7_5 {...props} />
        case 5:
          return <Y5 {...props} />
        case 2.5:
          return <Y2_5 {...props} />
        case 0:
          return <Y0 {...props} />
        default:
          return <></>
      }
    }

    return (
        <ResponsiveContainer width="95%" height={200}>
            <LineChart height={200} data={props.data} margin={{top: 25, right: 10, left: 10, bottom: 5}}>
                <defs>
                  <linearGradient id="backgroundGradMulti" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0" stopColor="#FDFDFD" stopOpacity={0} />
                    <stop offset="1" stopColor="#EDEDED" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} stroke="#A2A9AD" fill="url(#backgroundGradMulti)"/>
                <Line connectNulls type="monotone" dataKey="moodValue" stroke="#00ABC8" fill="#00ABC8" strokeWidth={3} />
                <Line connectNulls type="monotone" dataKey="painValue" stroke="#E82A6F" fill="#E82A6F" strokeWidth={3} />
                <Line connectNulls type="monotone" dataKey="stressValue" stroke="#853275" fill="#853275"strokeWidth={3} />
                {showWeekDay && <XAxis dataKey="dpDate" tickLine={false} tickFormatter={XAxisTick} interval={1} padding={{ left: 20, right: 20 }} />}
                {!showWeekDay && <XAxis dataKey="dpDate" tickLine={false} tickFormatter={XAxisTick} interval="preserveStartEnd" padding={{ left: 20, right: 20 }} />}
                <YAxis type="number" domain={[0, 10]} ticks={[0, 2.5, 5, 7.5, 10]} tick={YAxisTick} allowDecimals tickLine={false} axisLine={false}/>
                <Legend verticalAlign='bottom' align='center' iconType='circle' formatter={legendFormatter}/>
            </LineChart>
        </ResponsiveContainer>
	)

}

export default MultiLineChart