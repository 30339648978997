import { CardTitle } from '@careevolution/mydatahelps-ui';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import "./Accordian.css";
import MultiLineChart from './MultiLineChart';
import { FilterDateType, iDataReport, iMultiLineDataReport } from './MyData';
import SingleLineChart from './SingleLineChart';

export interface IChartAccordianProps {
  title: string,
  singleLineData?: iDataReport,
  multiLineData?: iMultiLineDataReport,
  dateFilter: FilterDateType;
};

const ChartAccordion = (props: IChartAccordianProps) => {
    const [isActive, setIsActive] = useState(true);
    const isSingleDataChart = props.singleLineData ? true : false;

    return (
      <li className="accordion-item">
        <div className="accordion-toggle accordianTitleBar rightMargins leftMargins">
            <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
              <div style={{ display: isActive===true? 'inline' : 'none'}}><FontAwesomeIcon icon={faAngleUp}/></div>
              <div style={{ display: isActive===false? 'inline' : 'none'}}><FontAwesomeIcon icon={faAngleDown}/></div>
              <div className='accordianColumn'>
                <div className="goalTitle1">GOAL TRACKING</div>
                <div><CardTitle title={props.title}/></div>
              </div>
            </div>
        </div>
        {isActive && isSingleDataChart && (props.dateFilter===FilterDateType.Week) && props.singleLineData && <SingleLineChart data={props.singleLineData?.weekData} dataGoal={props.singleLineData.dataGoal} />}
        {isActive && isSingleDataChart && (props.dateFilter===FilterDateType.Month) && props.singleLineData && <SingleLineChart data={props.singleLineData?.monthData} dataGoal={props.singleLineData.dataGoal} />}
        {isActive && !isSingleDataChart && (props.dateFilter===FilterDateType.Week) && props.multiLineData && <MultiLineChart data={props.multiLineData?.weekData} />}
        {isActive && !isSingleDataChart && (props.dateFilter===FilterDateType.Month) && props.multiLineData && <MultiLineChart data={props.multiLineData?.monthData} />}
      </li>
    );
  };
  
  export default ChartAccordion;